import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import clsx from "clsx";
import RHArrowIcon from "icons/RHArrowIcon";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { getAnalyticsAttrsFromProps } from "utils/analytics";
import memoize from "utils/memoize";

export interface TailwindButtonExtProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
  endIcon?: React.ReactNode;
  endIconSm?: boolean;
  endIconMd?: boolean;
  endIconStroke?: boolean;
  endIconStrokeSm?: boolean;
  endIconStrokeMd?: boolean;
  text?: string;
  textSm?: string;
  textMd?: string;
  hide?: boolean;
  hideSm?: boolean;
  hideMd?: boolean;
  onclick?: Function;
  startIcon?: React.ReactNode;
  startIconSm?: boolean;
  startIconMd?: boolean;
  variant?: "text" | "contained" | "outlined";
  variantSm?: "text" | "contained" | "outlined";
  variantMd?: "text" | "contained" | "outlined";
  color?:
    | "error"
    | "warning"
    | "success"
    | "inherit"
    | "primary"
    | "secondary"
    | "info";
  colorSm?: string;
  colorMd?: string;
  fullWidthSm?: boolean;
  hideClassNames?: string;
  width?: string;
  widthSm?: string;
  widthMd?: string;
  height?: string;
  heightSm?: string;
  heightMd?: string;
  padding?: string;
  paddingSm?: string;
  paddingMd?: string;
  align?: string;
  alignMd?: string;
  alignSm?: string;
  textWrap?: boolean;
  textWrapSm?: boolean;
  textWrapMd?: boolean;
  startIconSpacing?: number;
  endIconSpacing?: number;
  font?: string;
  fontMd?: string;
  fontSm?: string;
  fontSize?: number;
  fontSizeMd?: number;
  fontSizeSm?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  disableRipple?: boolean;
  href?: string;
}

const TailwindButton: React.FC<TailwindButtonExtProps> = props => {
  const {
    endIcon = null,
    endIconSm = null,
    endIconMd = null,
    endIconStroke = null,
    endIconStrokeSm = null,
    endIconStrokeMd = null,
    startIcon = null,
    startIconSm = null,
    startIconMd = null,
    hideMd,
    hideSm,
    variant = "text",
    variantSm = "text",
    variantMd = "text",
    align = "center",
    alignMd = "center",
    alignSm = "center",
    children,
    style,
    id = "tailwind-button",
    disabled = false,
    fullWidth = false,
    color = "primary",
    href
  } = props;

  const analyticsAttrs = getAnalyticsAttrsFromProps(props);

  let baseClass =
    "transition-all duration-1000 ease-linear whitespace-nowrap min-w-auto";
  let rootClass = clsx(baseClass, props.hideClassNames);
  let rootClassMd = rootClass;
  let rootClassSm = rootClass;

  const variantClasses = {
    text: "text-left whitespace-normal inline-flex",
    contained: "bg-primary text-white tracking-[1px]",
    outlined: "border border-primary text-primary",
    underline:
      "border-b  border-black font-[inherit] text-black not-italic leading-[120%] tracking-wider uppercase"
  };

  if (variantMd === "text" && alignMd === "left") {
    rootClassMd = clsx(baseClass, "text-left whitespace-normal inline");
  } else if (variantMd === "text" && alignMd === "right") {
    rootClassMd = clsx(baseClass, "text-right whitespace-normal inline");
  } else if (variantMd === "text" && alignMd === "center") {
    rootClassMd = clsx(baseClass, "text-center whitespace-normal inline");
  }

  if (variantSm === "text" && alignSm === "left") {
    rootClassSm = clsx(baseClass, "text-left whitespace-normal inline");
  } else if (variantSm === "text" && alignSm === "right") {
    rootClassSm = clsx(baseClass, "text-right whitespace-normal inline");
  } else if (variantSm === "text" && alignSm === "center") {
    rootClassSm = clsx(baseClass, "text-center whitespace-normal inline");
  }

  if (variant === "text" && align === "left") {
    rootClass = clsx(baseClass, "text-left whitespace-normal inline");
  } else if (variant === "text" && align === "right") {
    rootClass = clsx(baseClass, "text-right whitespace-normal inline");
  } else if (variant === "text" && align === "center") {
    rootClass = clsx(baseClass, "text-center whitespace-normal inline");
  }

  interface ButtonContentProps {
    isSm?: boolean;
    isMd?: boolean;
  }
  const ButtonContent: FC<ButtonContentProps> = ({ isSm, isMd }) => (
    <>
      {startIconSm && isSm ? <ArrowRightAltIcon /> : null}
      {startIconMd && isMd ? <ArrowRightAltIcon /> : null}
      {startIcon && !isSm && !isMd ? <ArrowRightAltIcon /> : null}
      <span className={`${variantClasses[variant]}`}>{children}</span>
      {endIconSm && isSm ? (
        <RHArrowIcon
          className="!text-[86%] !h-4 !w-4 ml-1"
          strokeColor={endIconStrokeSm ? "currentColor" : undefined}
          style={{ overflow: endIconStrokeSm ? "visible" : undefined }}
        />
      ) : null}
      {endIconMd && isMd ? (
        <RHArrowIcon
          className="!text-[86%] !h-4 !w-4 ml-1"
          strokeColor={endIconStrokeMd ? "currentColor" : undefined}
          style={{ overflow: endIconStrokeMd ? "visible" : undefined }}
        />
      ) : null}
      {endIcon && !isSm && !isMd ? (
        <RHArrowIcon
          className="!text-[86%] !h-4 !w-4 ml-1"
          strokeColor={endIconStroke ? "currentColor" : undefined}
          style={{ overflow: endIconStroke ? "visible" : undefined }}
        />
      ) : null}
    </>
  );
  const history = useHistory();
  const onClickButton = () => {
    history.push(href as any);
  };

  return (
    <button
      onClick={href ? onClickButton : props.onClick}
      {...analyticsAttrs}
      id={`tailwind-${id}`}
      data-testid={`tailwind-${id}`}
      {...props}
      className={
        props?.className
          ? clsx(
              "tailwind-button-base-style",
              {
                "bg-[#DDDDDD]": disabled && variant === "contained",
                "bg-black hover:!bg-gray-1 hover:!border-gray-1":
                  !disabled && variant === "contained" && color === "primary",
                "bg-white [&>span]:!text-black border-rh-light-grey border-solid border hover:!bg-[#FAFAFA]":
                  !disabled && variant === "contained" && color === "secondary",
                "tailwind-button-full-width": fullWidth
              },
              props?.className
            )
          : !hideSm
          ? rootClassSm
          : !hideMd && !hideSm
          ? rootClassMd
          : rootClass
      }
      style={{ ...style }}
    >
      <ButtonContent />
    </button>
  );
};

TailwindButton.defaultProps = {
  textWrap: true,
  textWrapSm: true,
  textWrapMd: true
};

export default memoize(TailwindButton);
