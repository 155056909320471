import React, { FC } from "react";

const defaultClassName =
  "fixed bottom-0 left-0 z-[1200] opacity-85 w-full py-2 px-4 bg-black text-white text-xs transition-transform duration-300";

interface QAStickyInfoProps {
  children?: React.ReactNode;
  className?: string;
  Toggle?: ({
    onClick,
    isOpen
  }: {
    onClick: () => void;
    isOpen: boolean;
  }) => JSX.Element;
}

const DefaultToggle = ({ onClick, isOpen }) => {
  return (
    <a
      onClick={onClick}
      className={
        "absolute top-0 right-0 text-inherit py-2 px-4 cursor-pointer text-xl leading-none inline-block"
      }
    >
      {isOpen ? "-" : "+"}
    </a>
  );
};

const QAStickyInfo: FC<QAStickyInfoProps> = ({
  children,
  className = defaultClassName,
  Toggle = DefaultToggle
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div
      id="qa-sticky-info"
      className={`${className} ${
        isOpen ? "translate-y-0" : "translate-y-[calc(100%-36px)]"
      }`}
    >
      <Toggle onClick={toggleOpen} isOpen={isOpen} />
      {children}
    </div>
  );
};

export default QAStickyInfo;
