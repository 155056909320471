import React, { FC } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Switch, Theme } from "@mui/material";
import memoize from "utils/memoize";
import clsx from "clsx";
interface RHSwitchProps {
  keyProp?: string;
  ariaLabel?: string;
  disabled?: boolean;
  value?: boolean;
  classNames?: string;
  setValue?: (switchValue: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1)
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#000",
          opacity: 1,
          border: "none"
        }
      },
      "&$focusVisible $thumb": {
        color: "#000",
        border: "6px solid #fff"
      }
    },
    switchBaseDisabled: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#F1F1F1",
          opacity: 1,
          border: "none"
        }
      },
      "&$focusVisible $thumb": {
        color: "#F1F1F1",
        border: "6px solid #fff"
      }
    },
    thumb: {
      width: 24,
      height: 24
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  })
);

const RHSwitch: FC<RHSwitchProps> = ({
  keyProp,
  ariaLabel,
  disabled,
  value,
  classNames,
  setValue
}) => {
  const classes = useStyles();
  return (
    <Switch
      id={"component-rh-switch"}
      key={keyProp}
      inputProps={{ "aria-label": ariaLabel || "Switch" }}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      disabled={disabled}
      checked={value}
      onChange={event => setValue(event.target.checked)}
      classes={{
        root: clsx(classes.root, classNames),
        switchBase: disabled ? classes.switchBaseDisabled : classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
    />
  );
};

export default memoize(RHSwitch);
