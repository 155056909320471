import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Intl Update Email Subscribe Form",
  type: "rh/components/content/intl-update-email-subscription-form",
  config: {
    emptyLabel: "Intl Update Email Subscribe Form",
    isEmpty: ({ title }: PropTypes) => !title
  }
};
