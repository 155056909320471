import clsx from "clsx";
import React, { forwardRef } from "react";

type Variant =
  | "p"
  | "span"
  | "div"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "caption"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "button"
  | "body2"
  | "overline"
  | "inherit";

type VariantHtmlName =
  | "p"
  | "span"
  | "div"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6";

interface TailwindTypographyProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  innerRef?: React.Ref<HTMLElement>;
  variant?: Variant;
  display?: string;
  paragraph?: boolean;
  gutterBottom?: boolean;
  component?: string;
  noWrap?: boolean;
}

const variantTagMap: Record<Variant, keyof JSX.IntrinsicElements | string> = {
  p: "p",
  span: "span",
  div: "div",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  caption: "span",
  subtitle1: "h6",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
  button: "span",
  inherit: "span",
  overline: "span"
};

const TailwindTypography = forwardRef<HTMLElement, TailwindTypographyProps>(
  (props, ref) => {
    const {
      children,
      id = "rh-tailwind-typography",
      className = "",
      innerRef,
      variant = "p",
      paragraph = false,
      display = "",
      gutterBottom = false,
      component = "",
      noWrap = false,
      ...restProps
    } = props;

    const combinedRef = (node: HTMLElement | null) => {
      if (typeof ref === "function") ref(node);
      else if (ref)
        (ref as React.MutableRefObject<HTMLElement | null>).current = node;
      if (typeof innerRef === "function") innerRef(node);
      else if (innerRef)
        (innerRef as React.MutableRefObject<HTMLElement | null>).current = node;
    };

    const TagType = (component ||
      variantTagMap[variant] ||
      "p") as VariantHtmlName;

    const combinedClassName = clsx([
      `tailwind-typography-root`,
      className,
      {
        ["mb-4"]: paragraph,
        ["mb-[0.35em]"]: gutterBottom,
        ["inline"]: display === "inline",
        ["block"]: display === "block",
        ["tailwind-typography-nowrap"]: noWrap,
        [`tailwind-typography-h1`]: variant === "h1",
        [`tailwind-typography-h2`]: variant === "h2",
        [`tailwind-typography-h3`]: variant === "h3",
        [`tailwind-typography-h4`]: variant === "h4",
        [`tailwind-typography-h5`]: variant === "h5",
        [`tailwind-typography-h6`]: variant === "h6",
        ["tailwind-typography-caption"]: variant === "caption",
        ["tailwind-typography-subtitle1"]: variant === "subtitle1",
        ["tailwind-typography-subtitle2"]: variant === "subtitle2",
        ["tailwind-typography-body1"]: variant === "p" || variant === "body1",
        ["tailwind-typography-body2"]: variant === "body2",
        ["tailwind-typography-button"]: variant === "button",
        ["tailwind-typography-overline"]: variant === "overline"
      }
    ]);

    return (
      <TagType
        className={combinedClassName}
        id={`tailwind-${id}`}
        data-testid={id}
        ref={combinedRef}
        {...restProps}
      >
        {children}
      </TagType>
    );
  }
);

TailwindTypography.displayName = "TailwindTypography";

export { TailwindTypography };
