import { Breakpoint } from "@mui/material";
import clsx from "clsx";
import React, { CSSProperties } from "react";
import { RHReferenceableComponentProps } from "types";
import { RHAnimatableComponentProps } from "utils/animation/types";
import { useAnimation } from "utils/animation/useAnimation";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS
} from "utils/constants";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import useIsHomePage from "hooks/useIsHomePage";
import { processEnvServer } from "hooks/useSsrHooks";

export type BoxProps = RHAnimatableComponentProps &
  RHReferenceableComponentProps & {
    className?: string;
    styles?: Partial<Record<Breakpoint, CSSProperties>>;
  };

type RestType = { authoringName?: string };

const useStyles = (props: BoxProps) => {
  return makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        clear: "both",
        [theme.breakpoints.up(BREAKPOINT_XS)]: props.styles?.xs,
        [theme.breakpoints.up(BREAKPOINT_SM)]: props.styles?.sm,
        [theme.breakpoints.up(BREAKPOINT_MD)]: props.styles?.md,
        [theme.breakpoints.up(BREAKPOINT_LG)]: props.styles?.lg,
        [theme.breakpoints.up(BREAKPOINT_XL)]: props.styles?.xl
      }
    });
  });
};

export const Box: React.FC<BoxProps> = ({
  domRefCallback,
  extraHTMLAttributes = {},
  rhUid,
  rhAnimation,
  ...props
}) => {
  const isHomePage = useIsHomePage();
  useAnimation({ rhUid, rhAnimation });

  const classes = useStyles(props)();

  const { children, ...rest } = props;

  const authoringNameArray = ["motion - wistia", "motion - media"];

  // Type guard to check if rest has authoringName property
  const hasAuthoringName = (obj: any): obj is RestType =>
    obj && "authoringName" in obj;

  const authoringName = hasAuthoringName(rest)
    ? (rest.authoringName || "").toLowerCase()
    : "";

  const isWistiaVideo =
    isHomePage && authoringNameArray.includes(authoringName);

  return (
    <div
      {...extraHTMLAttributes}
      {...rest}
      ref={domRefCallback}
      className={clsx(extraHTMLAttributes?.className, classes.root)}
      style={{
        ...(isWistiaVideo && processEnvServer
          ? { backgroundColor: "black", height: "1000px" }
          : {})
      }}
    >
      {children}
    </div>
  );
};

Box.defaultProps = {};

export default Box;
