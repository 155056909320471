import clsx from "clsx";
import React, { useMemo } from "react";
import {
  formatCurrencyPrice,
  getUserTypeLabel,
  isNullOrUndefined
} from "./helpers";
import { PriceProps } from "./types";
import RHLink from "@RHCommerceDev/component-rh-link";
import { CSSProperties } from "@mui/styles";

const RHRPriceDisplay = (props: PriceProps) => {
  const {
    productName,
    listPrice,
    memberPrice,
    topLabel,
    isSaleFilterEnabled,
    onSale,
    skulowestMemberPrice,
    showSaleMessage,
    userType,
    pageContent,
    computedSalePageContent,
    wrapPrices = false,
    centerAlignFlag = false,
    variant,
    showGiftCardPrice,
    showMembershipProductPrice,
    isCustomProduct,
    isSkuLevel,
    source,
    hideCss,
    onSaleLinkClick,
    saleUrl,
    metaProduct,
    emptyProduct,
    showInline = false
  } = props;
  const displayPriceComponent = listPrice && memberPrice;

  if (isCustomProduct || !displayPriceComponent) return <></>;
  const isOnSale =
    isSaleFilterEnabled && onSale ? true : onSale && !showSaleMessage;

  const checkSale = showInline && !isOnSale;
  const onSaleLinkAriaLabel = ` ${computedSalePageContent}  ${
    productName ?? ""
  }`;

  const userTypeLabel = useMemo(() => getUserTypeLabel(userType!), [userType]);
  const fontStyle = useMemo<CSSProperties>(() => {
    let style: CSSProperties = {
      fontWeight: "normal",
      lineHeight: "1.5rem"
    };
    if (variant === "small") {
      style = { ...style, fontSize: "11px" };
    } else if (variant === "medium") {
      style = { ...style, fontSize: "13px" };
    }
    if (variant === "print") {
      style = { fontSize: "25px", lineHeight: 1.43 };
    }
    return style;
  }, [variant]);

  const showViewSelectItemOnSale =
    showSaleMessage &&
    !isOnSale &&
    !showGiftCardPrice &&
    !showMembershipProductPrice &&
    !isCustomProduct &&
    !(!metaProduct && emptyProduct);

  const checkMemberShipGiftCard =
    !showGiftCardPrice && !showMembershipProductPrice;

  const memberPriceVal = useMemo(() => {
    return isOnSale ? skulowestMemberPrice : memberPrice;
  }, [isOnSale, memberPrice, skulowestMemberPrice]);

  return (
    <div
      className={clsx(
        `flex flex-col justify-center pr-2.5 w-full box-border xs:justify-start`,
        {
          "items-center": centerAlignFlag,
          "items-start": !centerAlignFlag
          // "mt-1.5 sm:mt-2 md:mt-2.5": !hideCss
        }
      )}
    >
      <div
        className={clsx(
          `flex flex-row items-baseline flex-wrap text-rhBlack tracking-[0.165px] leading-[18px]`,
          {
            "xs:items-center justify-center": !hideCss && centerAlignFlag,
            "whitespace-nowrap": !wrapPrices && source !== "spo",
            "text-center": wrapPrices && centerAlignFlag,
            "justify-left": hideCss && !centerAlignFlag,
            "text-[13px]": variant === "medium",
            "text-[11px]": !variant || variant !== "medium"
          }
        )}
      >
        {topLabel && !showMembershipProductPrice ? (
          <>
            {isOnSale && !isSkuLevel && (
              <span
                className={clsx(`uppercase my-0 mr-1`, {
                  "text-[#CA2022]": isOnSale,
                  "text-[#000]": !isOnSale
                })}
                id="RHPriceDisplaySale_sale_label"
              >
                {pageContent?.sale ? pageContent?.sale : pageContent?.SALE}
              </span>
            )}
            <span
              className={clsx("my-0 mr-1", {
                "text-[#CA2022]": isOnSale,
                "text-[#000]": !isOnSale,
                "whitespace-nowrap": hideCss && source === "spo"
              })}
              id="RHPriceDisplaySale_sale_top_label"
            >
              {topLabel}
            </span>
          </>
        ) : null}
        {!isNullOrUndefined(memberPriceVal) &&
          checkMemberShipGiftCard &&
          showInline && (
            <>
              <span
                className={clsx(
                  `${
                    source === "spo"
                      ? "tracking-[0.165px] leading-[18px]"
                      : "tracking-[0.04em]"
                  } font-primary-rhroman mr-1`,
                  {
                    "text-[#CA2022]": isOnSale,
                    "text-[#000]": !isOnSale
                  }
                )}
                id="RHPriceDisplaySale_member_price_inline"
              >
                {`${formatCurrencyPrice(memberPriceVal)}`}
              </span>
              {checkSale && (
                <span
                  className={clsx(
                    `${
                      source === "spo"
                        ? "tracking-[0.165px] leading-[18px]"
                        : "tracking-[0.04em]"
                    } font-primary-rhroman mr-1`,
                    {
                      "text-[#CA2022]": isOnSale,
                      "text-[#000]": !isOnSale,
                      "whitespace-nowrap": hideCss && source === "spo"
                    }
                  )}
                  id={`RHPriceDisplaySale_${
                    pageContent?.[userTypeLabel?.toUpperCase()]
                  }_price_label_inline`}
                >
                  {`${pageContent?.[userTypeLabel?.toUpperCase()]} /`}
                </span>
              )}
            </>
          )}
        {!isNullOrUndefined(memberPriceVal) &&
          checkMemberShipGiftCard &&
          !showInline && (
            <>
              <span
                className={clsx(
                  `${
                    source === "spo"
                      ? "tracking-[0.165px] leading-[18px]"
                      : "tracking-[0.04em]"
                  } font-primary-rhroman mr-1`,
                  {
                    "text-[#CA2022]": isOnSale,
                    "text-[#000]": !isOnSale
                  }
                )}
                id="RHPriceDisplaySale_member_price"
              >
                {formatCurrencyPrice(memberPriceVal)}
              </span>
            </>
          )}
        {!isNullOrUndefined(memberPriceVal) &&
          checkMemberShipGiftCard &&
          !checkSale && (
            <>
              <span
                className={clsx(
                  `${
                    source === "spo"
                      ? "tracking-[0.165px] leading-[18px]"
                      : "tracking-[0.04em]"
                  } font-primary-rhroman mr-1`,
                  {
                    "text-[#CA2022]": isOnSale,
                    "text-[#000]": !isOnSale
                  }
                )}
                id={`RHPriceDisplaySale_${
                  pageContent?.[userTypeLabel?.toUpperCase()]
                }_price_label`}
              >
                {`${pageContent?.[userTypeLabel?.toUpperCase()]} /`}
              </span>
            </>
          )}
        {!isNullOrUndefined(listPrice) && (
          <span>
            <span
              className={`${
                source === "spo"
                  ? "tracking-[0.165px] leading-[18px]"
                  : "tracking-[0.04em]"
              } font-primary-rhthin text-[#000] mr-1`}
              id="RHPriceDisplaySale_list_price"
            >
              {formatCurrencyPrice(listPrice)}
            </span>
            {checkMemberShipGiftCard && pageContent?.REGULAR && (
              <span
                className={`${
                  source === "spo"
                    ? "tracking-[0.165px] leading-[18px]"
                    : "tracking-[0.04em]"
                } font-primary-rhthin text-[#000]`}
                id="RHPriceDisplaySale_regular_price_label"
              >
                {pageContent?.REGULAR}
              </span>
            )}
          </span>
        )}
      </div>
      {showViewSelectItemOnSale && (
        <>
          <RHLink
            aria-label={onSaleLinkAriaLabel.toLowerCase()}
            underline="none"
            to={saleUrl && saleUrl}
            onClick={() => {
              onSaleLinkClick && onSaleLinkClick();
            }}
            tabIndex={0}
          >
            <div
              className={clsx(
                `items-baseline w-full flex flex-wrap box-border`,
                {
                  "justify-center text-center": centerAlignFlag
                }
              )}
              style={{ letterSpacing: "0.20px" }}
            >
              <div
                className={clsx(
                  `font-primary-rhroman font-normal leading-[2rem] text-[#CA2022]`,
                  {
                    underline: onSaleLinkClick || saleUrl,
                    "text-[13px]": variant === "medium",
                    "text-[11px]": variant === "small",
                    "text-[25px]": variant === "print"
                  }
                )}
              >
                {computedSalePageContent}
              </div>
            </div>
          </RHLink>
        </>
      )}
    </div>
  );
};
export default React.memo(RHRPriceDisplay);
