import React, { FC, useMemo } from "react";
import {
  ImageList,
  ImageListItem,
  ImageListProps,
  Typography,
  Theme
} from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useQuery } from "@apollo/client";
import { PromoProps } from "component-promo";
import useAppData from "hooks/useAppData";
import ProductCard from "component-product-card";
import { cssStringToObj } from "utils/cssStringToObj";
import useDidMountEffect from "hooks/useDidMountEffect";
import RHDivider from "component-rh-divider";
import {
  IMAGE_ASPECT_RATIO,
  INITIAL_PG_IMAGE_CONTAINER_DIMENSION,
  PG_IMAGE_CONTAINER_DIMENSION
} from "utils/constants";
import getCountryFromUrl from "utils/getCountryFromUrl";
import useTypographyStyles from "hooks/useTypographyStyles";
import { PAGE_BG_GREY } from "utils/constants";
import getCurrencyCode from "utils/getCurrencyCode";
import useLocale from "hooks-use-locale/useLocale";
import useMeasurementType from "hooks/useMeasurementType";
import useSite from "hooks/useSite";
import useMediaString from "hooks/useMediaString";
import _chunk from "lodash/chunk";
import prasePGCropRules from "utils/prasePGCropRules";
import { useRhUserAtomValue } from "hooks/atoms";
import { querySbrProducts } from "graphql-client/product";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

interface PromoProductGridProps extends PromoProps {
  GridListProps?: ImageListProps;
  gridColumns?: any;
}

interface ProductSubItemStart {
  subItemTitle: string;
}

export const PromoProductGrid: FC<PromoProductGridProps> = ({
  GridListProps,
  data,
  gridColumns
}) => {
  const {
    products: productsByCatId,
    dynamicText: { productIds, gridSize, style, categoryId } = {}
  } = data;
  const productIdList = productIds ? productIds.split(",") : [];
  const { userType } = useRhUserAtomValue();
  const {
    app: { postalCode }
  } = useAppData();

  const env = useEnv();
  const isSBREnhancedGridEnabled = yn(env.FEATURE_SBR_ENHANCED_GRID);
  const urlCountry = getCountryFromUrl();
  const siteId = useSite();
  const locale = useLocale();
  const currencyCode = getCurrencyCode({
    country: urlCountry,
    postalCode: postalCode,
    userType
  });

  const measureSystem = useMeasurementType();

  const {
    data: { shopByRoomProducts } = {} as Query,
    refetch: refetchSBRProducts
  } = useQuery<Query>(querySbrProducts, {
    variables: {
      productIds: productIdList,
      userType,
      siteId,
      measureSystem,
      locale,
      currencyCode
    },
    fetchPolicy: "no-cache",
    skip: !productIdList?.length || !!categoryId
  });

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH3"]
  });

  useDidMountEffect(() => {
    refetchSBRProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        margin: theme.spacing(2, 0),
        backgroundColor: PAGE_BG_GREY,
        ...cssStringToObj(style || "")
      },
      subTitle: {
        textAlign: "center",
        marginBottom: theme.spacing(2)
      },
      divider: {
        width: "100%"
      },
      reveal: {
        opacity: 0
      },
      fadeInUp: {
        animationDuration: "0s",
        animationTimingFunction: "easeIn",
        animationDelay: "0s",
        animationIterationCount: 1,
        animationDirection: "normal",
        animationFillMode: "none",
        animationPlayState: "running",
        animationName: "fadeInUp"
      },
      firstItem: isSBREnhancedGridEnabled
        ? {
            gridColumn: "1 / -1 !important",
            "& .MuiCardMedia-root": {
              width: "100%"
            },
            "& img": {
              width: "100%",
              objectFit: "contain",
              margin: "auto"
            }
          }
        : {}
    })
  )();

  const displaySingleProducts = () =>
    shopByRoomProducts?.filter(product => product?.isActive) as Product[];

  const displayCategoryProducts = (
    promoDisplay: PromoDisplay,
    totalProducts: any[] = []
  ) => {
    if (!promoDisplay.subItems) {
      return [
        ...totalProducts,
        ...(promoDisplay?.products?.productGallery || [])
      ];
    }

    for (let item of promoDisplay.subItems) {
      let currentProducts = [
        ...totalProducts,
        ...(item?.title ? [{ subItemTitle: item?.title }] : []),
        ...(item?.products?.productGallery || [])
      ];
      totalProducts = displayCategoryProducts(item, currentProducts) as any[];
    }

    return totalProducts;
  };

  const displayProducts = (
    productsByCatId
      ? displayCategoryProducts(data, productsByCatId?.productGallery)
      : displaySingleProducts()
  ) as any[];

  const mediaString = useMediaString();

  const parsedDerivedProductList = useMemo(() => {
    return [displayProducts]?.flatMap(derivedProductList =>
      _chunk(derivedProductList, 12 / gridColumns)?.flatMap(
        (groupedDerivedProduct: any) => {
          const [MAX_IMG_CONTAINER_HEIGHT] =
            PG_IMAGE_CONTAINER_DIMENSION?.[gridColumns]?.[mediaString] ??
            INITIAL_PG_IMAGE_CONTAINER_DIMENSION;

          return groupedDerivedProduct?.map((derivedProduct, index) => {
            const imgHeight = `${
              (prasePGCropRules(derivedProduct?.pgCropRules)?.height / 100) *
              MAX_IMG_CONTAINER_HEIGHT
            }px`;

            const isFirstItem =
              isSBREnhancedGridEnabled &&
              index === 0 &&
              groupedDerivedProduct.length >= 3;

            return {
              ...derivedProduct,
              imageStyle: {
                objectFit: "fill",
                alignSelf: "flex-end",
                maxWidth: isFirstItem ? "100%" : "100%",
                maxHeight: derivedProduct?.rhr ? imgHeight : "auto",
                width: "auto",
                height: "auto",
                transitionProperty: "opacity"
              },
              imageContainerStyle: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                aspectRatio: derivedProduct?.rhr
                  ? null
                  : IMAGE_ASPECT_RATIO["verticalProductTile"]?.toString(),
                height: MAX_IMG_CONTAINER_HEIGHT,
                width: isFirstItem ? "100%" : "auto"
              },
              isShopByRoom: true
            };
          });
        }
      )
    );
  }, [displayProducts, mediaString, gridColumns, isSBREnhancedGridEnabled]);

  if (!parsedDerivedProductList || !parsedDerivedProductList.length) {
    return null;
  }

  return (
    <ImageList
      cols={gridSize || 2}
      gap={10}
      {...GridListProps}
      className={clsx(classes.root, GridListProps?.className, "items-baseline")}
    >
      {parsedDerivedProductList?.map(
        (
          product: Product | CategoryProduct | ProductSubItemStart,
          index: number
        ) =>
          "subItemTitle" in product ? (
            <ImageListItem key={`subitems-${product.subItemTitle}`} cols={2}>
              <RHDivider
                style={{ backgroundColor: "white", marginBottom: "60px" }}
              />
              <Typography
                style={{ color: "white" }}
                className={clsx([
                  "promo-product-grid__subtitle",
                  classes.subTitle,
                  typographyStyles.rhBaseH3
                ])}
              >
                {product.subItemTitle}
              </Typography>
            </ImageListItem>
          ) : (
            <ImageListItem
              key={`product-${product.id}-${index}`}
              cols={
                isSBREnhancedGridEnabled &&
                index === 0 &&
                parsedDerivedProductList.length >= 3
                  ? 2
                  : 1
              }
              className={clsx({
                [classes.firstItem]:
                  isSBREnhancedGridEnabled &&
                  index === 0 &&
                  parsedDerivedProductList.length >= 3
              })}
            >
              <div
                key={`reveal-${index}`}
                className={clsx([classes.reveal, classes.fadeInUp])}
                style={{
                  animationDuration: "500ms",
                  animationDelay: "0ms",
                  animationIterationCount: 1,
                  opacity: 1,
                  display: "grid",
                  justifyContent: "center"
                }}
              >
                <ProductCard
                  data={product}
                  objectFit={"contain"}
                  RHImageContainerProps={{ className: `contents` }}
                  showPriceRange={!product?.customProduct}
                  imageSkeletonProps={{
                    className: "h-[75%]"
                  }}
                />
              </div>
            </ImageListItem>
          )
      )}
    </ImageList>
  );
};

PromoProductGrid.defaultProps = {};

export default PromoProductGrid;
