import clsx from "clsx";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHArrowIcon from "icon-arrow";
import RHCloseIcon from "icon-close";
import React, { FC } from "react";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
import { createStyles, makeStyles } from "@mui/styles";
import {
  DialogTitle,
  DialogTitleProps,
  IconButton as MuiIconButton,
  Theme,
  Typography as MuiTypography
} from "@mui/material";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
import TailwindButton from "@RHCommerceDev/component-tailwind-button";

export interface RHDialogTitleProps extends DialogTitleProps {
  onBack?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  titleClassNames?: string;
  titlePaddingClassNames?: string;
  closeIconClassnames?: string;
  closeButtonRootClassnames?: string;
  solidWhite?: boolean; // Temporary prop until the dialog types are hashed out
  subTitle?: string;
  anchor?: string;
  isDark?: boolean;
  styleTile?: any;
  closeIconClass?: string;
}

export const RHDialogTitle: FC<RHDialogTitleProps> = ({
  onBack,
  onClose,
  title,
  titleClassNames,
  titlePaddingClassNames,
  closeButtonRootClassnames,
  children,
  solidWhite,
  subTitle,
  anchor,
  styleTile,
  isDark = false,
  closeIconClass,
  ...rest
}) => {
  const isTailwindComponentEnabled = useTailwindComponent();
  const Typography = isTailwindComponentEnabled
    ? TailwindTypography
    : MuiTypography;
  const IconButton = isTailwindComponentEnabled
    ? TailwindButton
    : MuiIconButton;

  const { FEATURE_STYTCH } = getGlobal("_env");
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      icon: {
        top: "50%",
        transform: "translateY(-50%)"
      },
      title_padding: {
        padding: theme.spacing(3, 4),
        [theme.breakpoints.up("xs")]: {
          padding: theme.spacing(3.4, 4, 3, 5),
          ...rest.style
        },
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(4, 4, 3, 5),
          ...rest.style
        },
        [theme.breakpoints.up("xl")]: {
          padding: theme.spacing(7.62, 4, 3, 10),
          ...rest.style
        }
      }
    })
  );

  const classes = useTypographyStyles({
    keys: ["textCenter", "rhBaseCaption", "rhBaseBody1"]
  });

  const iconClasses = useStyles();
  return (
    <DialogTitle
      component="div"
      id={"component-rh-dialog-title"}
      disableTypography
      {...rest}
      style={{ ...rest.style }}
      className={clsx(
        `bg-[#f9f7f4] relative top-0 right-0 flex z-[1]`,
        //We can put this line back once we have a confirmation modal popup that has a main title
        //Make sure to change position: "absolute" to position: "relative" too
        iconClasses.title_padding,
        titlePaddingClassNames
      )}
    >
      {onBack && (
        <IconButton
          disableRipple
          className={clsx(iconClasses.icon, "!absolute left-1.5 z-20 p-[10px]")}
          color="inherit"
          onClick={event => onBack(event, "escapeKeyDown")}
        >
          <RHArrowIcon
            style={{
              transform: "rotate(180deg)"
            }}
          />
        </IconButton>
      )}

      {title && (
        <div className="flex flex-col">
          <Typography
            className={clsx(
              [
                { [classes.textCenter]: !Boolean(titleClassNames) },
                titleClassNames ? titleClassNames : classes.rhBaseBody1
              ],
              "!text-left break-words"
            )}
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
          {subTitle && (
            <Typography
              className={clsx(
                [
                  { [classes.textCenter]: !Boolean(titleClassNames) },
                  titleClassNames,
                  classes.rhBaseCaption
                ],
                "!text-left break-words"
              )}
              dangerouslySetInnerHTML={{
                __html: subTitle
              }}
            />
          )}
        </div>
      )}
      {children}
      {onClose && (
        <IconButton
          disableRipple
          className={clsx(
            iconClasses.icon,
            `!absolute ${
              FEATURE_STYTCH ? "!p-4 !right-0 w-[48px] " : "!p-[2px]"
            } right-1.5 !justify-start`,
            "!text-[20px] focus-visible:outline focus-visible:outline-[1px] focus-visible:outline-auto focus-visible:outline-[#015fcc]",
            anchor === "right" ? "-left-[46px]" : "",
            anchor === "left" ? "-right-[58px]" : "",
            rest.closeIconClassnames
          )}
          aria-label="Close"
          classes={{ root: closeButtonRootClassnames }}
          color="inherit"
          tabIndex={0}
          onClick={event => onClose(event, "escapeKeyDown")}
          data-testid="dialog-title-close-button"
        >
          <RHCloseIcon className={clsx(["!text-[2rem]", closeIconClass])} />
        </IconButton>
      )}
    </DialogTitle>
  );
};

RHDialogTitle.defaultProps = {};

export default RHDialogTitle;
