import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { queryCmrDebugInfo } from "@RHCommerceDev/graphql-client/queries/cmr-debug-info";

const Row = ({ label, data }) => {
  return (
    <tr>
      <td className="font-bold pr-1 min-w-24 align-baseline">{label}</td>
      <td className="align-baseline">{data}</td>
    </tr>
  );
};

const Header = ({ title, data }: { title: any; data?: any }) => {
  return (
    <thead>
      <tr>
        <th className="align-baseline  text-left  ">{title}</th>
        <th className=" align-baseline font-normal text-left ">
          {data || "\u00A0"}
        </th>
      </tr>
    </thead>
  );
};

const CmrDebugContent: FC = () => {
  const { data: { cmrDebugInfo } = {} as Query, loading } =
    useQuery<Query>(queryCmrDebugInfo);

  if (loading) return <React.Fragment>Loading...</React.Fragment>;

  return (
    <React.Fragment>
      <div className="md:flex">
        <div className="basis-[27.5%]">
          <table className="table-auto ">
            <Header title="BFF" data={cmrDebugInfo?.bffPath} />
            <tbody>
              <Row
                label="Preview"
                data={cmrDebugInfo?.isPreviewEnabled ? "On" : "Off"}
              />
              <Row
                label="Contentful Env."
                data={cmrDebugInfo?.contentfulEnvironment}
              />
            </tbody>
          </table>
        </div>
        <div className="basis-[27.5%]">
          <table className="table-auto">
            <Header
              title="CMR"
              data={cmrDebugInfo?.isCmrEnabled ? "On" : "Off"}
            />
            <tbody>
              <Row
                label="Colorization"
                data={cmrDebugInfo?.isColorizationEnabled ? "On" : "Off"}
              />
              <Row
                label=" Redis Caching"
                data={
                  cmrDebugInfo?.isRedisCacheEnabled ? "Enabled" : "Disabled"
                }
              />
            </tbody>
          </table>
        </div>
        <div className="basis-[45%]">
          <table className="table-auto">
            <Header title="Endpoints" />
            <tbody>
              <Row
                label="Cart Broker"
                data={cmrDebugInfo?.cartBrokerEndpoint}
              />
              <Row
                label="Colorization"
                data={cmrDebugInfo?.colorizationEndpoint}
              />
              <Row label="Fusion" data={cmrDebugInfo?.fusionEndpoint} />
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CmrDebugContent;
