import { processEnvServer } from "hooks/useSsrHooks";
import React, { FC, memo } from "react";
import { Helmet } from "react-helmet";
import { Theme, useMediaQuery } from "@mui/material";
import { BREAKPOINT_XL } from "utils/constants";

const srcSetWidths = [
  200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600
];

const buildSrcSet = url => {
  return srcSetWidths
    .map(w => {
      return `${url}?$responsive-gallery-image$&wid=${w}&fmt=png-alpha&bfc=on ${w}w`;
    })
    .join(", ");
};

const buildSrc = url =>
  `${url}?$responsive-gallery-image$&wid=400&fmt=png-alpha&bfc=on 400w`;

/* 


this implementation realies on the index.html 
look for the script tag with the id wistia_index_html_script
also with sessionProviderV2
if there is any wistiahero on the page it will be rendered on the server side some information, and we use that to set some flags and defer the 

*/

/* 
 for authors is better if the box2 or other parent container is sync about bgcolor
*/
declare global {
  interface Window {
    _wq: Array<any>;
    _wq_disabled_pipedream: boolean;
    _rh_wait_for_wistia_hero: boolean;
    _rh_on_wistia_hero_play: any;
  }
}

export type WistiaHeroProps = {
  smId: string;
  smPadding: string;
  mdId: string;
  mdPadding: string;
  lgId: string;
  lgPadding: string;
  hasThumbnail?: boolean;
  smImageDMURL?: string;
  mdImageDMURL?: string;
  lgImageDMURL?: string;
  smsrc?: string;
  mdsrc?: string;
  lgsrc?: string;
  smalt?: string;
  mdalt?: string;
  lgalt?: string;
};

const IPAD_VIDEO_UPPER_LIMIT = BREAKPOINT_XL;

const smMediaId = "(max-width: 767px)";
const sm2MediaId = `(min-width: 768px) and (max-width: 1364px)`;
const mdMediaId = `(min-width: 1365px) and (max-width: ${
  IPAD_VIDEO_UPPER_LIMIT - 1
}px)`;
const lgMediaId = `(min-width: ${IPAD_VIDEO_UPPER_LIMIT}px)`;

export type WistiaScriptProps = {
  id: string;
  media: string;
  media2?: string;
};
const WistiaScripts: FC<WistiaScriptProps> = ({ id, media, media2 }) => {
  const buildMedia = `${media}${media2 ? `, ${media2}` : ""}`;
  return (
    <Helmet>
      <link
        data-wistia-clean-up-helmet="true"
        rel="preload"
        href={`https://fast.wistia.com/embed/medias/${id}.json`}
        as="fetch"
        media={buildMedia}
        crossOrigin="anonymous"
        {...({ fetchpriority: "high" } as any)}
      />
      <link
        data-wistia-clean-up-helmet="true"
        rel="preload"
        href={`https://fast.wistia.com/embed/medias/${id}.m3u8`}
        as="fetch"
        media={buildMedia}
        crossOrigin="anonymous"
        {...({ fetchpriority: "high" } as any)}
      />

      <style data-wistia-clean-up-helmet="true" type="text/css">{`
            #wistia-${id} {
              display:none;
            }

            @media ${media} {
              #wistia-${id} {
                display:block;
              }
            }
            ${
              media2
                ? `@media ${media2} {
              #wistia-${id} {
                display:block;
              }
            }}`
                : ""
            }
            
          `}</style>
      <script data-wistia-clean-up-helmet="true" id={`wistia-on-load-${id}`}>{`
           window._wq = window._wq || [];
           _wq.push({ id: "${id}", onReady: function(video) {
             video.bind('play', function() {
              window._rh_on_wistia_hero_play?.forEach(fn => fn());
              window._rh_on_wistia_hero_play = [];
              window._rh_wait_for_wistia_hero = false;

               return video.unbind;
             });
           
           }});
          `}</script>
    </Helmet>
  );
};

const WistiaHero: FC<WistiaHeroProps> = ({
  smId,
  lgId,
  mdId,
  smPadding,
  lgPadding,
  mdPadding,
  hasThumbnail,
  smImageDMURL,
  mdImageDMURL,
  lgImageDMURL,
  smsrc,
  mdsrc,
  lgsrc,
  smalt,
  mdalt,
  lgalt
}) => {
  const isLgScreen = useMediaQuery<Theme>(
    theme => theme.breakpoints.up(IPAD_VIDEO_UPPER_LIMIT),
    {
      noSsr: !processEnvServer
    }
  );

  const showThumb = hasThumbnail;
  /* 
  
  on custom providers the query for the media is done on the server side
    const smDown = processEnvServer
    ? req.cookies.PF_EXP?.toLowerCase() === "mobile"
    : useMediaQuery(`(max-width: ${BREAKPOINT_SM}px)`);
  
    would be good to know the criteria for this on akamai, how they decide what is mobile and what is desktop

    also we only have 2 distinctions or mobile or desktop, we should decide if we want the video set on server or the front end

  */

  const isSmScreen = useMediaQuery<Theme>(
    theme => theme.breakpoints.down("sm"),
    {
      noSsr: !processEnvServer
    }
  );
  /* sm down means max-width 767 */

  // New query for detecting viewport between 768px and 1365px
  const isMdToLgScreen = useMediaQuery<Theme>(
    theme => theme.breakpoints.between("sm", 1365),
    {
      noSsr: !processEnvServer
    }
  );

  // New query for detecting viewport greater than 1600px
  const isXlScreen = useMediaQuery<Theme>("(min-width: 1600px)", {
    noSsr: !processEnvServer
  });

  // Use smId for viewport 0-767px
  // Use lgId for viewport 768-1365px OR >1600px
  // Use mdId for any other case (1366-1600px)
  const id = isSmScreen
    ? smId
    : isMdToLgScreen || isXlScreen || processEnvServer
    ? lgId
    : mdId;

  return (
    <>
      <Helmet>
        <script data-wistia-clean-up-helmet="true" id="wistia-main-data">{`
            window._rh_wait_for_wistia_hero = true;
          `}</script>
        <script
          data-wistia-clean-up-helmet="true"
          id="wistia_script"
          src="https://fast.wistia.com/assets/external/E-v1.js"
          async
          {...({ fetchpriority: "high" } as any)}
        ></script>
      </Helmet>
      {smId && <WistiaScripts id={smId} media={smMediaId} />}
      {mdId && <WistiaScripts id={mdId} media={mdMediaId} />}
      {lgId && (
        <WistiaScripts id={lgId} media={sm2MediaId} media2={lgMediaId} />
      )}
      <div
        style={{
          position: "relative",
          width: "100%",
          backgroundColor: "black"
        }}
      >
        <div id={`wistia-${smId}`} style={{ padding: smPadding }}></div>
        <div id={`wistia-${mdId}`} style={{ padding: mdPadding }}></div>
        <div id={`wistia-${lgId}`} style={{ padding: lgPadding }}></div>
        {showThumb && (
          <picture>
            <source
              srcSet={lgImageDMURL ? buildSrcSet(lgImageDMURL) : lgsrc}
              media={lgMediaId}
              sizes="100vw"
              title={lgalt}
            />
            <source
              srcSet={mdImageDMURL ? buildSrcSet(mdImageDMURL) : mdsrc}
              media="(min-width: 768px)"
              sizes="100vw"
              title={mdalt}
            />
            <img
              srcSet={smImageDMURL ? buildSrcSet(smImageDMURL) : smsrc}
              src={smImageDMURL ? buildSrc(smId) : smsrc}
              alt={smalt}
              style={{
                position: "absolute",
                inset: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
              decoding="async"
              sizes="100vw"
              loading="eager"
              {...{ fetchpriority: "high" }}
            />
          </picture>
        )}
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: showThumb ? "calc(100% - 0.5px)" : "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%"
          }}
        >
          {!processEnvServer && (
            <div
              className={`wistia_embed wistia_async_${id}`}
              key={id}
              {...({ seo: "true" } as any)}
              videoFoam="true"
              style={{
                height: "100%",
                left: 0,
                position: "relative",
                width: "100%"
              }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

const WistiaHeroMemo = memo(WistiaHero);

const PreWistiaMain: FC<any> = props => {
  const {
    smId,
    smPadding,
    mdId,
    mdPadding,
    lgId,
    lgPadding,
    hasThumbnail,
    smImageDMURL,
    mdImageDMURL,
    lgImageDMURL,
    smsrc,
    mdsrc,
    lgsrc,
    smalt,
    mdalt,
    lgalt
  } = props;

  return (
    <WistiaHeroMemo
      smId={smId}
      smPadding={smPadding}
      mdId={mdId}
      mdPadding={mdPadding}
      lgId={lgId}
      lgPadding={lgPadding}
      hasThumbnail={hasThumbnail}
      smImageDMURL={smImageDMURL}
      mdImageDMURL={mdImageDMURL}
      lgImageDMURL={lgImageDMURL}
      smsrc={smsrc}
      mdsrc={mdsrc}
      lgsrc={lgsrc}
      smalt={smalt}
      mdalt={mdalt}
      lgalt={lgalt}
    />
  );
};

export default PreWistiaMain;
