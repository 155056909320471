import React, { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { enable, disable } from "utils/scrollLock";
import memoize from "utils/memoize";
import useDidMountEffect from "hooks/useDidMountEffect";
import useIsIOSDevice from "hooks/useIsIOS";
import clsx from "clsx";

interface NewPDPProps {
  isNewPdpLayout?: boolean;
  stopBackgroundScrolling?: boolean;
}

type TransitionDurationType =
  | number
  | { appear?: number; enter?: number; exit?: number };

export type CombinedProps = NewPDPProps & {
  open?: boolean;
  onClose?: (event: any, reason: "backdropClick" | "escapeKeyDown") => void;
  onBack?: (event: any, reason: "backdropClick" | "escapeKeyDown") => void;
  id?: string;
  anchor?: "left" | "right" | "top" | "bottom";
  transitionDuration?: TransitionDurationType;
  classes?: {
    root?: string;
    paper?: string;
  };
  PaperProps?: {
    className?: string;
    style?: React.CSSProperties;
  };
  ModalProps?: {
    disablePortal?: boolean;
    style?: React.CSSProperties;
    disableRestoreFocus?: boolean;
  };
  BackdropProps?: {
    className?: string;
    invisible?: boolean;
    style?: React.CSSProperties;
  };
  className?: string;
  disableBackdropClick?: boolean;
  disableScrollLock?: boolean;
  elevation?: number;
  role?: string;
  tabIndex?: number;
  ariaLabel?: string;
  navigation?: {
    id: string;
  };
  disableRestoreFocus?: boolean;
  scrollToTopEnable?: boolean;
  disableToggleScroll?: boolean;
};

const TailwindDrawer: FC<CombinedProps> = props => {
  const {
    open = false,
    isNewPdpLayout,
    stopBackgroundScrolling = false,
    onClose,
    onBack,
    anchor = "right",
    transitionDuration = 500, // Default transition duration
    classes = {},
    PaperProps = {},
    ModalProps = {},
    BackdropProps = {},
    className = "",
    id = "tailwind-drawer",
    disableBackdropClick,
    disableScrollLock,
    elevation,
    role,
    tabIndex,
    ariaLabel,
    navigation,
    disableRestoreFocus,
    scrollToTopEnable = true,
    disableToggleScroll = false,
    ...rest
  } = props;
  const [isMounted, setIsMounted] = useState(false);

  const toggleScroll = (scroll: boolean, isNewPdpLayout?: boolean): void => {
    if (!isNewPdpLayout && !disableToggleScroll) {
      document.documentElement.style.overflow = scroll ? "visible" : "hidden";
    }
  };

  const isIOSDevice = useIsIOSDevice();
  const portalContainerRef = useRef(document.createElement("div"));

  // useEffect(() => {
  //   if (open) {
  //     console.warn("scroll to top useEffect");
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   }
  // }, [open]);
  useEffect(() => {
    const portalContainer = portalContainerRef.current;
    document.body.appendChild(portalContainer);

    return () => {
      document.body.removeChild(portalContainer);
    };
  }, []);
  useEffect(() => {
    //* SR-4222: In the old MUI component we have this prop scrollToTopEnable which needs to be true for this scroll functionality to run. This prop has 'true' as default value (in old drawer aswell) and needs to be explicitly set to false to disable the scroll to top functionality.
    if (scrollToTopEnable) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (open || stopBackgroundScrolling) {
      toggleScroll(false, isNewPdpLayout);
    } else {
      toggleScroll(true, isNewPdpLayout);
    }
    return () => toggleScroll(false, isNewPdpLayout);
  }, [open, stopBackgroundScrolling, isNewPdpLayout]);

  useDidMountEffect(() => {
    if (!isIOSDevice) return;

    if (open) {
      enable?.();
    }

    return () => disable();
  }, [open]);

  useEffect(() => {
    if (open) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => {
        setIsMounted(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [open, transitionDuration]);

  const getAnchorClasses = (anchor: string) => {
    switch (anchor) {
      case "left":
        return "inset-y-0 !left-0 w-1/3 !h-full right-auto top-0 bottom-auto !flex !flex-col !absolute md:!overflow-auto";
      case "right":
        return "inset-y-0 !right-0 md:w-1/3 w-full !h-full left-auto top-0 bottom-auto !flex !flex-col !absolute md:!overflow-auto";
      case "top":
        return "inset-x-0 top-0 bottom-auto h-auto max-h-full !overflow-y-auto";
      case "bottom":
        return "inset-x-0 bottom-0 !fixed left-0 right-0 h-auto !overflow-y-auto";
      default:
        return "inset-y-0 right-0 w-1/3";
    }
  };

  const getTransformStyle = (open: boolean, anchor: string) => {
    switch (anchor) {
      case "left":
        return open ? "translateX(0)" : "translateX(-100%)";
      case "right":
        return open ? "translateX(0)" : "translateX(100%)";
      case "top":
        return open ? "translateY(0)" : "translateY(-100%)";
      case "bottom":
        return open ? "translateY(0)" : "translateY(100%)";
      default:
        return open ? "translateX(0)" : "translateX(100%)";
    }
  };

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (onClose) {
      onClose(event, "backdropClick");
    }
  };

  const drawerClasses = clsx(
    `relative bg-white shadow-drawer ${getAnchorClasses(anchor)} ${
      classes.paper || ""
    } ${PaperProps.className || ""} ${className}`,
    {
      sidebar: navigation?.id === "/my-account/index.jsp"
    }
  );

  const drawerContent = (
    <div
      id={"tailwind-drawer"}
      data-testid="tailwind-drawer"
      role="presentation"
      className={`fixed inset-0 z-[1300] transition-transform ${
        classes.root || ""
      }`}
      style={ModalProps.style}
      {...rest}
    >
      <div
        data-testid="backdrop"
        className={`fixed inset-0 bg-gray-500/40 ${
          BackdropProps.invisible && "opacity-0"
        } ${BackdropProps.className || ""}`}
        style={BackdropProps.style}
        onClick={disableBackdropClick ? undefined : handleBackdropClick}
      ></div>
      <div
        role={role}
        tabIndex={tabIndex}
        aria-label={ariaLabel}
        className={`${drawerClasses} z-[1200] md:overflow-y-auto`}
        style={{
          transform: getTransformStyle(isMounted && open, anchor),
          transition: `transform ${
            typeof transitionDuration === "number"
              ? `${transitionDuration}ms`
              : "500ms"
          } cubic-bezier(0, 0, 0.2, 1)`,
          ...PaperProps.style
        }}
      >
        {props.children}
      </div>
    </div>
  );

  return open || isMounted
    ? createPortal(drawerContent, portalContainerRef.current)
    : null;
};

export default memoize(TailwindDrawer);
