import React, { FC, useMemo, useState } from "react";
import RHStickyModal from "component-rh-sticky-modal";
import CookiePermissionBanner from "@RHCommerceDev/component-rh-cookie-permission-banner";
import CookieSelectionModal from "component-rh-cookie-selection-modal";
import { useEnv } from "hooks/useEnv";
import Cookies from "js-cookie";
import yn from "yn";
import { REQUIRED_PERMISSION_COUNTRIES } from "utils/constants";
import { Utils } from "@adobe/aem-react-editable-components";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { useCookiePreferencesAtomValue, useRhUserAtomValue } from "hooks/atoms";
import memoize from "utils/memoize";
import { useAppId } from "hooks/useAppId";
import getCountryFromUrl, {
  getLanguageFromUrl
} from "@RHCommerceDev/utils/getCountryFromUrl";
import { usePageContentNoProxy } from "customProviders/LocationProvider";
import { processModelResponse } from "graphql-client/queries/get-aem-model";
import { useAemFetchHook } from "aem/fetchModel";
import { client } from "graphql-client";
import { USER_COOKIE_PREFERENCES } from "@RHCommerceDev/utils/constants";

import {
  languageToggleExcludedCountries,
  cookieAemLanguageMapper
} from "resources/countries-config.json";
import { processEnvServer } from "hooks/useSsrHooks";

const isEditor = Utils.isInEditor();

const env = useEnv();
const FEATURE_SUPPRESS_UK = yn(env.FEATURE_SUPPRESS_UK);
const FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE = yn(
  env?.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
);

const RHCookiePermissionBannerWrapper: FC = () => {
  const { isEstore, isConcierge } = useAppId();
  const [isOpenedCookiePreferences, setOpenedCookiePreferences] =
    useState(false);
  const { raw: currentLanguage } = getLanguageFromUrl();
  const [cookieLanguage, setCookieLanguage] = useState<string>(
    currentLanguage.toUpperCase() || ""
  );
  const cookiePreferences = useCookiePreferencesAtomValue();
  const { cookiePermissionCountry } = useCookiesWithPermission();
  const country = getCountryFromUrl();
  const { loading: isRhUserLoading } = useRhUserAtomValue();
  const isUserSavedCookiePreferences = env.FEATURE_INT_COOKIE_PREFERENCE
    ? yn(cookiePreferences?.userSavedCookiePreferences) ??
      yn(
        JSON.parse(Cookies.get(USER_COOKIE_PREFERENCES) ?? "{}")
          ?.userSavedCookiePreferences
      )
    : yn(cookiePreferences?.userSavedCookiePreferences);
  const showBanner = useMemo(() => {
    if (isUserSavedCookiePreferences) {
      return false;
    }
    return REQUIRED_PERMISSION_COUNTRIES?.includes(
      cookiePermissionCountry ? cookiePermissionCountry : country
    );
  }, [cookiePermissionCountry, country, isUserSavedCookiePreferences]);

  const showLanguageToggle =
    isEstore &&
    !languageToggleExcludedCountries.includes(country) &&
    !FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE;

  let { pageContent, loading: initialLoading } = usePageContentNoProxy();

  const languageCode = useMemo(
    () => cookieAemLanguageMapper[cookieLanguage],
    [cookieLanguage]
  );

  const isLangEnglish = languageCode === "en";

  // Always call useAemFetchHook to avoid page crash, but handle the case with skip variable
  const aemFetchResult = useAemFetchHook({
    path: `/rh/${country?.toLowerCase()}/${languageCode?.toLowerCase()}/admin/cookie-modal`,
    isJSPPage: false,
    isPage: true,
    graphqlClient: client,
    skip: isLangEnglish ? true : false
  });

  pageContent = isLangEnglish
    ? pageContent?.cookieModal
    : processModelResponse(aemFetchResult?.data);

  const loading =
    languageCode === "en" ? initialLoading : aemFetchResult.loading;

  const cookieEnabled = !FEATURE_SUPPRESS_UK && showBanner;

  return cookieEnabled && !isEditor && !isRhUserLoading ? (
    <>
      {isEstore &&
        typeof isEstore !== "undefined" &&
        !isConcierge &&
        !isOpenedCookiePreferences &&
        !processEnvServer && (
          <RHStickyModal>
            <CookiePermissionBanner
              cookieLanguageProps={
                showLanguageToggle
                  ? {
                      onSelectLanguage: value => setCookieLanguage(value),
                      languageTogglePageContent: pageContent,
                      selectedLanguage: cookieLanguage,
                      loading: loading
                    }
                  : {}
              }
              openCookieSelectionModal={value =>
                setOpenedCookiePreferences(value)
              }
              showBanner={showBanner}
            />
          </RHStickyModal>
        )}
      <CookieSelectionModal
        open={isOpenedCookiePreferences}
        setCookieSelectionModal={value => setOpenedCookiePreferences(value)}
        languageTogglePageContent={showLanguageToggle ? pageContent : undefined}
      />
    </>
  ) : null;
};

export default memoize(RHCookiePermissionBannerWrapper);
