import getImageUrlWithPreset, {
  ProductImagePresetKeys
} from "@RHCommerceDev/utils/getImageUrlWithPreset";
import memoize from "@RHCommerceDev/utils/memoize";

const preloadImages: { [key: string]: boolean } = {};

export interface ImagePreloaderProps {
  preset?: ProductImagePresetKeys;
  images: Array<string>;
}

export const ImagePreloader: React.FC<ImagePreloaderProps> = memoize(
  ({ images, preset }) => {
    images.forEach(src => {
      const url = getImageUrlWithPreset(src, preset);
      if (url && !preloadImages[url]) {
        preloadImages[url] = true;
        let img = new Image();
        img.onload = () => {};
        img.src = url;
      }
    });
    return null;
  }
);
