import {
  useState,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  useRef
} from "react";
import { processEnvServer } from "hooks/useSsrHooks";
import React, { FC } from "react";
import sanitizeHtml from "sanitize-html";
import { createStyles, makeStyles } from "@mui/styles";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { getSelectorsByUserAgent } from "react-device-detect";

import {
  Theme,
  IconButton,
  useTheme,
  InputBase,
  InputBaseProps,
  useMediaQuery
} from "@mui/material";
import RHRSearchIcon from "icon-search-rhr";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { useLocalization } from "hooks/useLocalization";
import { usePageContent } from "customProviders/LocationProvider";
import { SEARCH_LABEL, SUBMIT_SEARCH } from "resources/rh-header.json";
import { useTypographyStyles } from "hooks/useTypographyStyles";
import clsx from "clsx";
import {
  FONT_BARON_SANS,
  RH_MEDIUM,
  RH_DARK,
  RH_BLACK,
  RH_WHITE,
  BCT_PATHS
} from "utils/constants";
import { codePattern } from "utils/regex";
import { formatSearchText } from "utils/formatSearchUrl";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import { useHistory } from "react-router";
import { useSetSipId } from "@RHCommerceDev/hooks/atoms/useSIPID";
import useSite from "hooks/useSite";
import { useAppId } from "hooks/useAppId";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";

export const useStyles = (isDarkMode, color) =>
  makeStyles((theme: Theme) =>
    createStyles({
      searchIconContainer: {
        padding: "5px 0 5px 3px !important",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: "1 !important",
        borderRadius: "0px !important",
        marginLeft: "6px !important",
        "&:focus": {
          outline: "#015fcc auto 1px !important"
        }
      },
      searchInputRoot: {
        borderRadius: "38px !important",
        width: "187px !important",
        height: "36px !important",
        background: "transparent !important",
        border: `0.75px solid ${
          color
            ? color
            : isDarkMode
            ? theme?.palette?.common?.white
            : theme?.palette?.common?.black
        } !important`
      },
      searchInput: {
        padding: "8px !important",
        marginLeft: "12px !important",
        paddingLeft: "32px !important",
        fontFamily: `${FONT_BARON_SANS} !important`,
        color: `${isDarkMode ? RH_MEDIUM : RH_DARK} !important`
      },
      searchTypingText: {
        color: `${
          isDarkMode ? `${RH_WHITE} !important` : `${RH_BLACK} !important`
        }`
      }
    })
  );

interface HeaderSearchTextFieldProps extends InputBaseProps {
  isDarkMode?: boolean;
  isHeaderSearch?: boolean;
  openDrawer: () => void;
}

const HeaderSearchTextField: FC<
  HeaderSearchTextFieldProps & { color?: string }
> = ({
  isHeaderSearch,
  isDarkMode,
  openDrawer,
  color = undefined,
  ...props
}) => {
  const siteId = useSite();
  const history = useHistory();
  const classes = useStyles(isDarkMode, color)();
  const rhTypoClasses = useTypographyStyles({
    keys: ["reimagineBody2"]
  });
  const theme = useTheme();
  const prefix = useLocalization();
  const [searchText, setSearchText] = useState<string>("");
  const [showTypeAhead, setShowTypeAhead] = useState(false);
  const { setStorageValueWrapper } = useCookiesWithPermission();
  const { pageContent } = usePageContent();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isTailwindComponentEnabled = useTailwindComponent();
  const env = useEnv();
  const req = getReqContext();
  let mobile = false;
  const userAgentSSR = req && req?.headers["user-agent"];
  if (userAgentSSR) {
    const { isMobile } = getSelectorsByUserAgent(userAgentSSR);
    mobile = !!isMobile;
  }
  const setSipId = useSetSipId();
  const inputRef = useRef<HTMLInputElement>(null);
  const handleIconClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);
  const { isConcierge } = useAppId();

  const handleTypeahead = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!processEnvServer) {
        setStorageValueWrapper({
          storageKey: "analytics-access",
          value: "typeahead"
        });
      }
      setSearchText(event.target.value);
      if (!showTypeAhead && !isHeaderSearch) {
        setShowTypeAhead(true);
      }
    },
    [showTypeAhead, isHeaderSearch, setStorageValueWrapper]
  );

  const handleEnterPressed = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const bctPath =
        yn(env?.FEATURE_BCT_SUNSET) && !isConcierge
          ? BCT_PATHS[siteId] || ""
          : "";
      const searchPath = yn(env?.FEATURE_URL_CHANGE)
        ? formatSearchText(searchText)
        : `results.jsp?Ntt=${encodeURIComponent(
            searchText?.trim()
          )}&Ns=product.sale%7C1`;
      if (
        !!searchText &&
        event.key === "Enter" &&
        !!codePattern.test(searchText)
      ) {
        // RESET SIP ID
        setSipId(null);

        if (!processEnvServer) {
          sessionStorage.removeItem("pdpViewItem");
          sessionStorage.removeItem("rhrGridChoice");
          setStorageValueWrapper({
            storageKey: "analytics-access",
            value: "direct"
          });
          setStorageValueWrapper({
            storageKey: "analytics-searchbar-enter",
            value: searchText
          });
        }
        //Removed the feature flag FEATURE_PG_ENHANCEMENT
        history.push(sanitizeHtml(`${prefix}${bctPath}/search/${searchPath}`));

        setSearchText("");
      }
    },

    [searchText, prefix, setStorageValueWrapper]
  );

  return (
    <div className={"relative"}>
      <IconButton
        disableRipple
        id="container-rhrSearchField_search-btn"
        onClick={handleIconClick}
        className={`!absolute !py-[5px] !pr-[3px] !pl-[5px] !ml-[6px] !rounded-none ${classes.searchIconContainer}`}
        aria-label={pageContent?.HEADER_CONTENT?.SUBMIT_SEARCH ?? SUBMIT_SEARCH}
        data-testid="search-icon"
      >
        <RHRSearchIcon
          className={"!w-[26px] !h-[26px] sm:w-6.5 sm:h-6.5"}
          style={{
            color: color
              ? color
              : isDarkMode
              ? theme.palette.common.white
              : theme.palette.common.black
          }}
        />
      </IconButton>
      {((!processEnvServer ? smUp : !mobile) ||
        (isTailwindComponentEnabled && smUp)) && (
        <InputBase
          sx={{
            "& .MuiInputBase-input": {
              padding: "8px 8px 8px 32px !important"
            }
          }}
          {...props}
          inputRef={inputRef}
          value={searchText}
          className={classes?.searchInputRoot}
          inputProps={{
            className: clsx(
              classes?.searchInput,
              rhTypoClasses.reimagineBody2,
              {
                [classes?.searchTypingText]: searchText?.length
              }
            ),
            "aria-label":
              pageContent?.HEADER_CONTENT?.SEARCH_LABEL ?? SEARCH_LABEL,
            placeholder: searchText
              ? searchText
              : pageContent?.HEADER_CONTENT?.SEARCH_LABEL
              ? pageContent?.HEADER_CONTENT?.SEARCH_LABEL
              : SEARCH_LABEL
          }}
          onChange={handleTypeahead}
          onKeyUp={handleEnterPressed}
          data-testid="search-input"
        />
      )}
    </div>
  );
};

export default HeaderSearchTextField;
