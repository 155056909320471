import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { RadioProps } from "@mui/material/Radio";
import { Radio } from "@mui/material";
import memoize from "@RHCommerceDev/utils/memoize";
import clsx from "clsx";
import React, { FC } from "react";

export type RHRadioProps = RadioProps & { customClass?: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: 1,
      borderRadius: 0,
      "&.Mui-focusVisible": {
        outline: "2px solid #1976d2",
        outlineOffset: "2px"
      }
    },
    checkedIcon: {
      "&:before": {
        display: "block",
        position: "absolute",
        width: 10,
        height: 10,
        top: "50%",
        left: "50%",
        borderRadius: 7,
        transform: "translate(-50%, -50%)",
        backgroundColor: "#000000",
        content: '""'
      }
    }
  })
);

export const RHRadio: FC<RHRadioProps> = ({ ...rest }) => {
  const classes = useStyles();

  const props = {
    disableRipple: true,
    checkedIcon: (
      <span
        className={clsx(
          "w-6 h-6 rounded-[15px] bg-white border-solid border-8",
          classes.checkedIcon,
          rest?.customClass
        )}
      />
    ),
    icon: (
      <span
        className={`w-6 h-6 rounded-[15px] bg-white border-solid border-8 ${rest?.customClass}`}
      />
    ),
    ...rest,
    className: clsx(classes.root, rest.className)
  };

  return <Radio {...props} />;
};

RHRadio.defaultProps = {};

export default memoize(RHRadio);
