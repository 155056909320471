import React, { FC, useState, useEffect } from "react";
import { Hidden, IconButton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import RHImageV2 from "@RHCommerceDev/rh-image-component";
import { useQuery } from "@apollo/client";
import { PromoProps } from "component-promo";
import RHAddIcon from "icon-add";
import ProductOverlayDialog from "dialog-product-overlay";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import useMediaString from "hooks/useMediaString";
import useUserPreferences from "hooks/useUserPreferences";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import { useIsoCookies } from "hooks/useIsoCookies";
import useAppData from "hooks/useAppData";
import useLocale from "hooks-use-locale/useLocale";
import useMeasurementType from "hooks/useMeasurementType";
import useSite from "hooks/useSite";
import { useRhUserAtomValue } from "hooks/atoms";
import { querySbrProducts } from "graphql-client/product";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";
import TailwindButton from "@RHCommerceDev/component-tailwind-button";

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      position: "absolute",
      padding: "5px",
      background: "#ffffffaa",
      "&:focus-visible": {
        outline: "#015fcc auto 1px"
      }
    }
  })
);

export const PromoImageLifeStyle: FC<PromoProps> = ({
  data,
  loadingHeight,
  imageLifeStyleProps,
  ariaLabelPrefix,
  ...rest
}) => {
  const mediaString = useMediaString();
  const isTailwindComponentEnabled = useTailwindComponent();
  const [productId, setProductId] = useState("");
  const [productIds, setProductIds] = useState<string[]>([]);
  const showAssets = imageLifeStyleProps?.showAssets ?? true;
  const { userType } = useRhUserAtomValue();

  const classes = useStyles();

  useEffect(() => {
    if (data?.catalogAssets) {
      setProductIds(data.catalogAssets.map(asset => asset.productId));
    }
  }, [data]);

  const siteId = useSite();
  const locale = useLocale();
  const { app } = useAppData();
  const pc = useIsoCookies(["pc"])?.pc;
  const {
    previousState: { country }
  } = useUserPreferences();
  const currencyCode = useCurrencyCode({
    postalCode: app.postalCode || pc,
    userType,
    country
  });
  const measureSystem = useMeasurementType();

  const { data: { shopByRoomProducts } = {} as Query } = useQuery<Query>(
    querySbrProducts,
    {
      variables: {
        productIds: productIds,
        userType,
        siteId,
        measureSystem,
        locale,
        currencyCode
      },
      fetchPolicy: "no-cache",
      skip: !productIds.length
    }
  );

  return (
    <>
      <div>
        <RHImageV2
          src={data.imageUrl || ""}
          preset={`full-width-${mediaString}` as ProductImagePresetKeys}
          loading="eager" // to fix image load issue - This component is only used inside slick carousel
          {...rest}
        />
        {showAssets && (
          <Hidden smDown>
            {data.catalogAssets
              .filter(catalogAsset => {
                return shopByRoomProducts?.find(
                  product => product.id === catalogAsset.productId
                )?.isActive;
              })
              .map((catalogAsset, index) =>
                !isTailwindComponentEnabled ? (
                  <IconButton
                    disableRipple
                    key={catalogAsset.id}
                    className={classes.iconButton}
                    style={{
                      left: `${catalogAsset.leftCoordinate - 1.5 ?? 0}%`,
                      top: `${catalogAsset.topCoordinate - 2 ?? 0}%`
                    }}
                    onClick={event => {
                      event.preventDefault();
                      setProductId(catalogAsset.productId);
                    }}
                    aria-label={`${ariaLabelPrefix ?? ""} ${
                      shopByRoomProducts[index]?.displayName
                    }`}
                  >
                    <RHAddIcon toggle={false} />
                  </IconButton>
                ) : (
                  <TailwindButton
                    key={catalogAsset.id}
                    className={`${classes.iconButton} rounded-full hover:bg-transparent`}
                    style={{
                      left: `${catalogAsset.leftCoordinate - 1.5 ?? 0}%`,
                      top: `${catalogAsset.topCoordinate - 2 ?? 0}%`
                    }}
                    onClick={event => {
                      event.preventDefault();
                      setProductId(catalogAsset.productId);
                    }}
                    aria-label={`${ariaLabelPrefix ?? ""} ${
                      shopByRoomProducts[index]?.displayName
                    }`}
                  >
                    <RHAddIcon className="opacity-[0.54]" toggle={false} />
                  </TailwindButton>
                )
              )}
          </Hidden>
        )}{" "}
      </div>
      <Hidden smDown>
        <ProductOverlayDialog
          open={!!productId}
          onClose={() => setProductId("")}
          productId={productId}
        />
      </Hidden>
    </>
  );
};

PromoImageLifeStyle.defaultProps = {};

export default PromoImageLifeStyle;
