import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import RHImageV2 from "@RHCommerceDev/rh-image-component";
import { PromoProps } from "component-promo";
import handlePromoUrl from "utils/handlePromoUrl";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import useMediaString from "hooks/useMediaString";
import useIsoRedirect from "hooks-use-isoredirect";

export const PromoImage: FC<PromoProps> = ({
  data,
  loadingHeight,
  ...props
}) => {
  const history = useHistory();
  const mediaString = useMediaString();

  const handleOnClick = useCallback(() => {
    if (data?.targetUrl) {
      if (data?.linkType === "new-window") {
        const win = useIsoRedirect(data?.targetUrl, "windowOpen");
        if (win) {
          (win as Window).focus();
        }
      } else {
        handlePromoUrl(data?.targetUrl, history);
      }
    }
  }, [data?.linkType, data?.targetUrl, history]);

  return data?.imageUrl ? (
    <button onClick={handleOnClick}>
      <RHImageV2
        src={data.imageUrl || undefined}
        alt={data.dynamicText.alt || "RH Promo"}
        preset={`full-width-${mediaString}` as ProductImagePresetKeys}
        {...props}
      />
    </button>
  ) : null;
};

PromoImage.defaultProps = {};

export default PromoImage;
