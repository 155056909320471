import useIsIOSDevice from "hooks/useIsIOS";
import React, { FC, useEffect } from "react";
import { RHR_DRAWER_TRANSITION_DURATION } from "utils/constants";
import { DrawerProps, Drawer as MuiDrawer } from "@mui/material";
import memoize from "utils/memoize";
import { setInitialDocumentOverFlow } from "utils/scrollLock";

interface NewPDPProps {}

type CombinedProps = DrawerProps &
  NewPDPProps & {
    scrollToTopEnable?: boolean;
  };

const Drawer: FC<CombinedProps> = props => {
  const { open, scrollToTopEnable = true } = props;
  const isIOSDevice = useIsIOSDevice();

  useEffect(() => {
    setInitialDocumentOverFlow();
  }, []);

  //MUIDrawer from mui v5 prevents background scrolling by default
  // useDidMountEffect(() => {
  //  if (!isIOSDevice) return;

  //   if (open) {
  //     enable?.();
  //   }

  //   return () => disable();
  // }, [open]);

  // when the instack modal is open below code shifting the focus to the top - SR-3566
  useEffect(() => {
    if (open && scrollToTopEnable) {
      window.scrollTo({
        top: 0
      });
    }
  }, [open]);

  return (
    <MuiDrawer
      id={props?.id || "component-drawer"}
      transitionDuration={RHR_DRAWER_TRANSITION_DURATION}
      {...props}
    />
  );
};

export default memoize(Drawer);
